import { LocationDescriptorObject } from "history";
import {IUser, IMonetaryAmount} from "shared/Types/appTypes";

export enum UserActionTypes {
    INVALID_LOGIN = 'INVALID_LOGIN',
    GET_USER = 'GET_USER',
    SET_USER = 'SET_USER',
    SET_USER_ACCOUNT_BALANCE = 'SET_USER_ACCOUNT_BALANCE',
    LOGOUT_USER = 'LOGOUT_USER',
    CLEAR_USER = 'CLEAR_USER',
    INVALID_USER = 'INVALID_USER'
}

/*
export interface IInvalidLogin {
    readonly type: UserActionTypes.INVALID_LOGIN,
    readonly message: string
    readonly username: string,
}
*/

export type ContinueOptions = Readonly<{
    location: LocationDescriptorObject
    oneTimeToken: string
}>

export interface IGetUser {
    readonly type: UserActionTypes.GET_USER
}

export interface ISetUserAccountBalance {
    readonly type: UserActionTypes.SET_USER_ACCOUNT_BALANCE,
    readonly balance: IMonetaryAmount
}

export interface ILogout {
    readonly type: UserActionTypes.LOGOUT_USER
    readonly redirect: boolean
    readonly continueOptions?: ContinueOptions
}

/*
export const invalidLogin = (message: string, username: string): IInvalidLogin => ({
    type: UserActionTypes.INVALID_LOGIN,
    message,
    username,
})
*/

export const getUser = (): IGetUser => ({
    type: UserActionTypes.GET_USER
});

export interface IValidUser {
    readonly type: UserActionTypes.SET_USER
    readonly user: IUser
}

export interface IClearUser {
    readonly type: UserActionTypes.CLEAR_USER
}

// @TODO: Logout when invalid
export interface IInvalidUser {
    readonly type: UserActionTypes.INVALID_USER
}

export const setUser = (user: IUser): IValidUser => ({
    type: UserActionTypes.SET_USER,
    user,
});

export function setUserAccountBalance (newBalance: IMonetaryAmount): ISetUserAccountBalance {
    return { type: UserActionTypes.SET_USER_ACCOUNT_BALANCE, balance: newBalance }
}

export const clearUser = (): IClearUser => ({
    type: UserActionTypes.CLEAR_USER
});

export const logout = (redirect: boolean = true, continueOpts?: ContinueOptions): ILogout => ({
    type: UserActionTypes.LOGOUT_USER,
    redirect,
    continueOptions: continueOpts,
});

export type UserAction = IValidUser | ISetUserAccountBalance | IClearUser | ILogout | IInvalidUser | IGetUser
