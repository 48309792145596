import React, { useEffect, useState } from "react"
import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { isIOS } from "react-device-detect"
import { Localized, useLocalization } from "@fluent/react"
import { useSnackbar } from "notistack"
import { FormikValues, useFormik } from "formik"
import * as yup from "yup"
import { Logger } from "shared/Helpers/logging"
import useTimeout from "shared/hooks/useTimeout"
import { useUrlQueryParams } from "shared/hooks/useUrlQueryParams"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { authenticateByCode } from "../loginActions"
import { selectActivationCodeCommunicationChannel, selectAuthenticationResultMessage } from "../loginSelectors"
import { FormActionArea, FormHeader, FormInputArea, FormOneTimeCodeField, FormParagraph, FormProgress, LoginForm, LoginScreen, SubmitButton } from "../Components/LoginForm"

type ActivationCodeProps = Readonly<{

}>

type ActivateByLinkProps = Readonly<{
    token: string
    dispatch: Dispatch
}>

const logger = new Logger("activation-code")
const autoActivateDelayMillis = 1500

function ActivateByLink({ token, dispatch }: ActivateByLinkProps) {
    useTimeout(() => {
        dispatch(authenticateByCode(token, false, () => {}))
    }, autoActivateDelayMillis)

    return (
        <LoginForm>
            <LocalizedStrict id="authenticate-activation-code-header">
                <FormHeader>Aktiver konto</FormHeader>
            </LocalizedStrict>
            <FormInputArea>
                <Localized id="authenticate-activation-code-automatic-activation-text">
                    <FormParagraph>Vent venligst mens vi aktiverer dig...</FormParagraph>
                </Localized>
            </FormInputArea>
            <FormProgress visible />
        </LoginForm>
    )
}

interface FormValues extends FormikValues {
    readonly activationCode: string
}

type ActivateByCodeProps = Readonly<{
    message: string | undefined
    channel: "EMAIL" | "TEXT_MESSAGE" | undefined
    dispatch: Dispatch
}>

function ActivateByCode({ message, channel, dispatch }: ActivateByCodeProps) {
    const codeLength = 6
    const { l10n } = useLocalization()

    const formik = useFormik<FormValues>({
        initialValues: {
            activationCode: ""
        },
        validationSchema: yup.object().shape({
            activationCode: yup.string().required(
                l10n.getString("authenticate-activation-code-code-field-required", undefined, "Du skal indtaste en aktiveringskode")
            ).min(codeLength,
                l10n.getString("authenticate-activation-code-code-field-min-len", { codeLength }, `Aktiveringskode er på min. ${codeLength} tegn`))
        }),
        onSubmit: ((values) => {
            return new Promise<void>((resolve) => {
                dispatch(authenticateByCode(values.activationCode, true, resolve))
            })
        })
    })

    const channelFallback = (message?.includes("SMS") ?? false) ? "TEXT_MESSAGE" : "EMAIL"
    const displayMode = ((channel ?? channelFallback) === "TEXT_MESSAGE" && isIOS) ? "IOS_AUTO_COMPLETE" : "DEFAULT"
    logger.info(`Display mode: ${displayMode} [isIOS: ${isIOS}, channel: ${channel}, channelFallback: ${channelFallback}, message: ${message}]`)

    return (
        <LoginForm onSubmit={formik.handleSubmit}>
            <LocalizedStrict id="authenticate-activation-code-header">
                <FormHeader>Aktiver konto</FormHeader>
            </LocalizedStrict>
            <FormInputArea>
                {message ? (
                    <FormParagraph>{message}</FormParagraph>
                ) : (
                    <Localized id="authenticate-activation-code-manual-activation-text">
                        <FormParagraph>Indtast venligst den aktiveringskode, som du har modtaget på email eller SMS:</FormParagraph>
                    </Localized>
                )}
                <Localized id="authenticate-activation-code-activation-code-input" attrs={{ label: true }}>
                    <FormOneTimeCodeField
                        codeLength={codeLength}
                        label="Aktiveringskode"
                        name="activationCode"
                        value={formik.values.activationCode}
                        error={formik.touched.activationCode && Boolean(formik.errors.activationCode)}
                        helperText={formik.touched.activationCode && formik.errors.activationCode}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        setValue={(value) => formik.setFieldValue("activationCode", value)}
                        submitForm={formik.submitForm}
                    />
                </Localized>
            </FormInputArea>
            <FormActionArea>
                <LocalizedStrict id="authenticate-activation-code-submit-button">
                    <SubmitButton disabled={!formik.isValid} loading={formik.isSubmitting} loadingLabel="Aktiverer...">
                        Fortsæt
                    </SubmitButton>
                </LocalizedStrict>
            </FormActionArea>
        </LoginForm>
    )
}

export function ActivationCode({}: ActivationCodeProps) {
    const logger = new Logger("activation-code")
    const [token, setToken] = useState<string>()
    const [message, setMessage] = useState<string>()

    const authResultMessage = useSelector(selectAuthenticationResultMessage)
    const activationCodeChannel = useSelector(selectActivationCodeCommunicationChannel)
    const dispatch = useDispatch()

    const [tokenParam, messageParam] = useUrlQueryParams(["token", "message"])
    const { enqueueSnackbar } = useSnackbar()

    logger.info(`Got token: ${Boolean(tokenParam)}`)

    useEffect(() => {
        if (tokenParam != null) setToken(tokenParam)
        if (messageParam != null) setMessage(decodeURIComponent(messageParam))
    }, [tokenParam, setToken, messageParam, setMessage])

    useEffect(() => {
        if (message) {
            enqueueSnackbar(message, { variant: "success" })
        }
    }, [message])

    return (
        <LoginScreen backButtonNavigatesTo="previous">
            {token ? (
                <ActivateByLink token={token} dispatch={dispatch} />
            ) : (
                <ActivateByCode message={authResultMessage} channel={activationCodeChannel?.type} dispatch={dispatch} />
            )}
        </LoginScreen>
    )
}
