import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { logout } from "shared/Modules/User/userActions"
import { selectIsLoggedIn } from '../loginSelectors'
import { useUrlQueryParams } from 'shared/hooks/useUrlQueryParams'
import { useLocation } from 'react-router'
import { Logger } from 'shared/Helpers/logging'

type Props = PropsWithChildren<{}>

function Authenticated({ children }: Props) {
    const logger = new Logger("login")

    const location = useLocation()

    const [token, _setToken] = useState<string | null>(new URLSearchParams(location.search).get("token"))

    const loggedIn = useSelector(selectIsLoggedIn)
    const dispatch = useDispatch()

    useEffect(() => {
        // Ensure user is logged in
        if (!loggedIn && token) {
            logger.info(`Authenticated path but user not logged in [otp: true, redirect to auto login]`)
            dispatch(logout(true, { location, oneTimeToken: token }))
            return
        } else if (!loggedIn) {
            logger.info(`Authenticated path but user not logged in [otp: false, redirect to login]`)
            dispatch(logout())
            return
        }
    }, [loggedIn, token]);

    // NOTE: Do NOT render children if unauthenticated!
    if (!loggedIn) return null

    return (
        <>
            {children}
        </>
    );
};

export default Authenticated
