import React, { useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"
import LuxonUtils from "@date-io/luxon"
import { useSelector } from "react-redux"
import { Localized } from "@fluent/react"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-light-svg-icons/faFilter"
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, Tab, Tabs } from "@material-ui/core"
import { selectConfigProperties } from "shared/Modules/Properties/propertySelectors"
import { getLocalizationIdForEnum } from "shared/Modules/Localization/localization"
import { StandardButton } from "shared/Components/Button/StandardButton"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import { FormDrawer } from "shared/Components/Dialog/FormDrawer"
import { MyOrdersHeader } from "./components/MyOrdersHeader"
import { useToken } from "shared/Modules/Login/useToken"
import { useQuery } from "shared/Modules/Query/useQuery"
import Screen from "shared/Components/Skeleton/Screen"
import { getMyOrders } from "mobile/Api/GetMyOrders"
import { OrderType } from "shared/Types/appTypes"
import { Logger } from "shared/Helpers/logging"
import MyOrdersData from "./MyOrdersData"

enum TabType {
    PURCHASES = "PURCHASES",
    WALLET_REFILLS = "WALLET_REFILLS",
}

type TabDefinition = Readonly<{
    type: TabType
    orderTypes: {
        type: OrderType
        enabled: boolean
    }[]
}>

const useStyles = makeStyles((theme) => ({
    selectedTab: {
        marginTop: theme.spacing(5),
    },
    tabs: {
        borderBottom: "1px solid #e8e8e8",
    },
    datepickerContainer: {
        marginTop: theme.spacing(),
        marginBottom: 2,
    },
    datepicker: {
        maxWidth: 100,
    },
}))

function getAvailableTabs(tabDefs: TabDefinition[]) {
    return tabDefs
        .filter((tab) => tab.orderTypes.some((t) => t.enabled))
        .map((tab) => ({
            type: tab.type,
            orderTypes: tab.orderTypes.filter((t) => t.enabled).map((t) => t.type),
        }))
}

export default function MyOrders() {
    const token = useToken()
    const dateTimeFactory = useDateTime()
    const history = useHistory()
    const classes = useStyles()
    const properties = useSelector(selectConfigProperties)

    const tabDefinitions: TabDefinition[] = [
        {
            type: TabType.PURCHASES,
            orderTypes: [
                { type: OrderType.LUNCH, enabled: true },
                { type: OrderType.TAKE_AWAY, enabled: true },
                { type: OrderType.CATERING, enabled: true },
            ],
        },
        {
            type: TabType.WALLET_REFILLS,
            orderTypes: [{ type: OrderType.REFILL_ACCOUNT, enabled: properties.showRefillAccountModule }],
        },
    ]

    const availableTabs = useMemo(() => {
        const tabs = getAvailableTabs(tabDefinitions)
        if (!tabs.some((tab) => tab.type === TabType.PURCHASES)) {
            tabs.unshift({ type: TabType.PURCHASES, orderTypes: [] })
        }
        return tabs
    }, [
        properties.showLunchModule,
        properties.showTakeAwayModule,
        properties.showCateringModule,
        properties.showRefillAccountModule,
    ])

    const initialTabIndex = availableTabs.length > 0 ? 0 : -1
    const initialSelectedOrderTypes = initialTabIndex >= 0 ? availableTabs[initialTabIndex].orderTypes : []

    const [startDate, setStartDate] = useState<DateTime>()
    const [endDate, setEndDate] = useState<DateTime>()
    const [tab, setTab] = useState(0)
    const [selectedOrderTypes, setSelectedOrderTypes] = useState(initialSelectedOrderTypes)
    const [filtersOpen, setFiltersOpen] = useState(false)

    const { QueryPane } = useQuery(
        () => getMyOrders(selectedOrderTypes, token, startDate?.toISODate(), endDate?.toISODate()),
        [selectedOrderTypes, token, startDate, endDate],
    )

    const logger = new Logger("my-orders")

    function handleStartDateChange(date: any) {
        date = date as DateTime
        setStartDate(date)
        if (endDate && date.toMillis() > endDate?.toMillis()) {
            setEndDate(date)
        }
    }

    function handleEndDateChange(date: any) {
        date = date as DateTime
        setEndDate(date)
        if (startDate && startDate.toMillis() > date.toMillis()) {
            setStartDate(date)
        }
    }

    function handleTabChange(tab: number) {
        setTab(tab)
        setSelectedOrderTypes(availableTabs[tab].orderTypes)
    }

    function handleOrderTypeFilterChange(orderType: OrderType, checked: boolean) {
        if (checked && !selectedOrderTypes.includes(orderType)) {
            setSelectedOrderTypes([orderType, ...selectedOrderTypes])
        } else if (!checked && selectedOrderTypes.includes(orderType)) {
            setSelectedOrderTypes(selectedOrderTypes.filter((t) => t !== orderType))
        }
    }

    function getLocalizationIdForFilters() {
        const available = availableTabs[tab].orderTypes.length
        const selected = selectedOrderTypes.length

        if (selected === 0) return "my-orders-filter-button-none"
        if (selected === available) return "my-orders-filter-button-all"
        if (selected > 1) return "my-orders-filter-button-some"
        return getLocalizationIdForEnum("my-orders-filter-button", selectedOrderTypes[0])
    }

    useEffect(() => {
        // NOTE: This may reset filter if user changed order types
        logger.info("Available tabs updated [action: update filter]", availableTabs)
        setSelectedOrderTypes(availableTabs[tab].orderTypes)
    }, [availableTabs])

    return (
        <Localized id="my-orders-page" attrs={{ primaryTopBarTitle: true }}>
            <Screen
                name="Home"
                primaryTopBarTitle="Mine ordrer"
                onBackButtonPress={history.goBack}
                alternativeHeaderElement={
                    <Localized id="my-orders-page-header" attrs={{ title: true }}>
                        <MyOrdersHeader title="Mine ordrer" onBackButtonPress={history.goBack} />
                    </Localized>
                }
                showHeaderSection={false}
            >
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Tabs value={tab} indicatorColor="primary" className={classes.tabs}>
                            {availableTabs.map((tab, index) => (
                                <Localized
                                    key={index}
                                    id={getLocalizationIdForEnum("my-orders-tab", tab.type)}
                                    attrs={{ label: true }}
                                >
                                    <Tab label={tab.type} onClick={() => handleTabChange(index)} />
                                </Localized>
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <Grid
                                container
                                spacing={1}
                                wrap="nowrap"
                                alignItems="center"
                                className={classes.datepickerContainer}
                            >
                                <Grid item>
                                    <FontAwesomeIcon icon={faCalendar} size="lg" />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        className={classes.datepicker}
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        size="small"
                                        labelFunc={(d) => dateTimeFactory.formatDate(d ?? dateTimeFactory.now())}
                                    />
                                </Grid>
                                <Grid item>
                                    <span>-</span>
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        className={classes.datepicker}
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        size="small"
                                        labelFunc={(d) => dateTimeFactory.formatDate(d ?? dateTimeFactory.now())}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {availableTabs[tab].orderTypes.length > 1 && (
                        <Grid item>
                            <Localized
                                id={getLocalizationIdForFilters()}
                                vars={{
                                    selected: selectedOrderTypes.length,
                                    available: availableTabs[tab].orderTypes.length,
                                }}
                            >
                                <StandardButton
                                    variant="outlined"
                                    size="small"
                                    fullWidth={false}
                                    startIcon={<FontAwesomeIcon icon={faFilter} />}
                                    onClick={() => setFiltersOpen(true)}
                                >
                                    Alle
                                </StandardButton>
                            </Localized>
                        </Grid>
                    )}
                    <Grid item>
                        <QueryPane>
                            {(data) => (
                                <MyOrdersData
                                    initialData={data.orders}
                                    initialOrdersSummary={data.ordersSummary?.totalAmount}
                                    initialNextLink={data.pagination?.nextLink?.href}
                                />
                            )}
                        </QueryPane>
                    </Grid>
                </Grid>
                <Localized id="my-orders-filter-modal" attrs={{ title: true, cancelButtonLabel: true }}>
                    <FormDrawer
                        name="filters"
                        open={filtersOpen}
                        onClose={() => setFiltersOpen(false)}
                        title="Filtre"
                        cancelButtonLabel="Luk"
                    >
                        <FormControl component="fieldset">
                            {/*
                                <Localized id="my-orders-filter-order-type-label">
                                    <FormLabel component="legend">Ordretyper</FormLabel>
                                </Localized>
                            */}
                            <FormGroup>
                                {/* l10n id: my-orders-filter-order-type-lunch etc */}
                                {availableTabs[tab].orderTypes.map((orderType, index) => (
                                    <Localized
                                        key={index}
                                        id={getLocalizationIdForEnum("my-orders-filter-order-type", orderType)}
                                        attrs={{ label: true }}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            checked={selectedOrderTypes.includes(orderType)}
                                            onChange={(_e, checked) => handleOrderTypeFilterChange(orderType, checked)}
                                            label={orderType}
                                        />
                                    </Localized>
                                ))}
                            </FormGroup>
                        </FormControl>
                    </FormDrawer>
                </Localized>
            </Screen>
        </Localized>
    )
}
