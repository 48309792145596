import { Reducer } from 'redux'
import { SharedAction } from 'shared/Types/actionTypes'
import { Logger } from 'shared/Helpers/logging'
import { Login, LoginStatus, UserLogin } from './loginTypes'
import { LoginActionTypes } from './loginActions'

const authenticationKey = "authentication"

function safeParseFromLocalStorage<T>(key: string): T | undefined {
    try {
        const value = localStorage.getItem(key)
        if (value !== null) return JSON.parse(value)
    } catch (error) {
        console.warn(`could not parse item from local storage with key ${key}`)
        localStorage.removeItem(key)
    }

    return undefined
}

function getInitialState(): Login {
    const authentication = safeParseFromLocalStorage<UserLogin>(authenticationKey)
    if (authentication) {
        return {
            loginStatus: LoginStatus.LOGGED_IN,
            authentication,
        }
    }

    return {
        loginStatus: LoginStatus.AUTHENTICATING,
        flow: {},
        loading: false,
    }
}

const initialState = getInitialState()

const loginReducer: Reducer<Login, SharedAction> = (state = initialState, action) => {
    const logger = new Logger("loginReducer")

    switch (action.type) {
        case LoginActionTypes.SELECT_FLOW:
            if (state.loginStatus === LoginStatus.LOGGED_IN) {
                logger.warn(`Action ${action.type} not valid when login status is ${state.loginStatus}`)
                return state
            }
            return {
                ...state,
                flow: {
                    current: action.flow,
                },
            }
        case LoginActionTypes.WRONG_FLOW:
            if (state.loginStatus === LoginStatus.LOGGED_IN) {
                logger.warn(`Action ${action.type} not valid when login status is ${state.loginStatus}`)
                return state
            }
            return {
                ...state,
                flow: {
                    ...state.flow,
                    correct: action.correctFlow,
                }
            }
        case LoginActionTypes.CHECK_USERNAME:
            return {
                ...state,
                loading: true,
                username: action.username,
            }
        case LoginActionTypes.ACTIVATE_BY_EMAIL:
        case LoginActionTypes.ACTIVATE_BY_PHONE:
        case LoginActionTypes.AUTHENTICATE_BY_PASSWORD:
        case LoginActionTypes.AUTHENTICATE_BY_CODE:
        case LoginActionTypes.CREATE_ACCOUNT:
        case LoginActionTypes.CREATE_PASSWORD:
        case LoginActionTypes.RESET_PASSWORD:
            return {
                ...state,
                loading: true,
            }
        case LoginActionTypes.SELECT_COMPANY:
            return {
                ...state,
                loading: true,
                companyChoice: action.companyChoice,
            }
        case LoginActionTypes.CONFIRM_ACCOUNT:
            return {
                ...state,
                loading: false,
                account: action.account,
            }
        case LoginActionTypes.AUTHENTICATION_CONTINUE:
            return {
                ...state,
                loading: false,
                response: action.response,
            }
        case LoginActionTypes.AUTHENTICATION_COMPLETE:
            localStorage.setItem(authenticationKey, JSON.stringify(action.user))
            return {
                ...state,
                loginStatus: LoginStatus.LOGGED_IN,
                authentication: action.user,
            }
        case LoginActionTypes.AUTHENTICATION_FAILED:
            return {
                ...state,
                loading: false
            }
        case LoginActionTypes.NAVIGATE_BACK:
            return {
                ...state,
                response: undefined
            }
        case LoginActionTypes.RESET_LOGIN:
            localStorage.removeItem(authenticationKey)
            return {
                ...state,
                loginStatus: LoginStatus.AUTHENTICATING,
                flow: {},
                loading: false,
                response: undefined,
                authentication: undefined,
                continueLocation: action.continueOptions?.location,
            }
        default:
            return state
    }
}

export default loginReducer
