import { SagaIterator } from 'redux-saga'
import { all, put, take, call, select, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { IUser, UserSettingElementType } from 'shared/Types/appTypes'
import { IEmptySuccessResponse } from 'shared/Types/responseTypes'
import { Logger } from 'shared/Helpers/logging'
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from 'shared/Helpers/SagaHelper'
import { selectCurrentEnvironment } from 'shared/Modules/Environment/envSelectors'
import { LoginChoice } from 'shared/Modules/Login/loginTypes'
import { LoginActionTypes, resetLogin, selectFlow } from 'shared/Modules/Login/loginActions'
import { selectUserUid, selectApiToken } from 'shared/Modules/Login/loginSelectors'
import { pickBestLocale } from 'shared/Modules/Localization/localization'
import { ISetLocale, LocalizationActionTypes, setLocale } from 'shared/Modules/Localization/localizationActions'
import { selectLocale } from 'shared/Modules/Localization/useLocale'
import { UserActionTypes, getUser as getUserAction, setUser, clearUser, ILogout } from './userActions'
import { selectUserLocale } from './userSelectors'
import { getUser, saveUserSetting } from './userAPI'
import { locationToString } from 'shared/Helpers/UrlHelper'

const logger = new Logger("user")

export function* getUserSaga(): SagaIterator {
    while (true) {
        yield take(UserActionTypes.GET_USER);
        yield call(updateUser);
    }
}

export function* updateUser(isBackgroundRequest = false): SagaIterator {
    const uid = yield select(selectUserUid);
    const token = yield select(selectApiToken);
    const environment = yield select(selectCurrentEnvironment)

    if (uid && token) {
        const responseData: IUser | null = yield* apiRequestSagaWithOptions(
            getUser, {
                isBackgroundRequest,
            },
            token,
            uid,
            environment
        )

        if (responseData) yield put(setUser(responseData))
    }
}

export function* loadUserAccountOnLoginSaga(): SagaIterator {
    while (true) {
        yield take(LoginActionTypes.AUTHENTICATION_COMPLETE)
        logger.info("Detected user login: loading user account")
        yield put(getUserAction())
    }
}

export function* renegotiateUserLocaleSaga(): SagaIterator {
    while (true) {
        yield take(UserActionTypes.SET_USER)
        const userLocale: string | undefined = yield select(selectUserLocale)
        const currentLocale: string = yield select(selectLocale)
        if (userLocale) {
            const selectedLocale = pickBestLocale(userLocale)
            if (selectedLocale !== currentLocale) {
                logger.info(`Detected new user locale, renegotiated locale from ${currentLocale} to ${selectedLocale}`)
                yield put(setLocale(selectedLocale))
            }
        }
    }
}

function* emptySuccessHandler(response: IEmptySuccessResponse) {
    return response.responseCode >= 200 && response.responseCode < 300
}

export function* saveUserLocaleSaga(): SagaIterator {
    while (true) {
        const { locale }: ISetLocale = yield take(LocalizationActionTypes.SET_LOCALE)
        const token = yield select(selectApiToken)
        const environment = yield select(selectCurrentEnvironment)

        if (token) {
            logger.info("Detected locale change [save user locale]")
            yield* apiRequestSagaWithOptions(
                saveUserSetting, {
                    responseHandler: {
                        emptySuccess: emptySuccessHandler
                    }
                }, {
                    name: "LANGUAGE_CODE",
                    elementType: UserSettingElementType.DROP_DOWN,
                    value: locale,
                },
                token,
                environment,
            )
        } else {
            logger.info("Detected locale change but user not logged in [ignore]")
        }
    }
}

export function* logoutSaga(): SagaIterator {
    while (true) {
        const { redirect, continueOptions }: ILogout = yield take(UserActionTypes.LOGOUT_USER)
        logger.info(`Log out user and ${(redirect ? "" : "do not ")}redirect to login [continue URL: ${locationToString(continueOptions?.location)}]`)
        yield put(clearUser())
        yield put(resetLogin(continueOptions))
        if (redirect && continueOptions) {
            yield put(selectFlow({ loginChoice: LoginChoice.Login }))
            yield put(push(`/login/otp?token=${continueOptions.oneTimeToken}`))
        } else if (redirect) {
            // Reset flow when navigating back past choice
            yield put(selectFlow(undefined))
            yield put(push('/login'))
        }
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getUserSaga),
        fork(errorHandlerSagaCreator, errorLogger, loadUserAccountOnLoginSaga),
        fork(errorHandlerSagaCreator, errorLogger, renegotiateUserLocaleSaga),
        fork(errorHandlerSagaCreator, errorLogger, saveUserLocaleSaga),
        fork(errorHandlerSagaCreator, errorLogger, logoutSaga),
    ])
}
