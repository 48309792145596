import { DateTime } from "luxon"
import { useCallback, useMemo } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useLocalization } from "@fluent/react"
import { WebshopType } from "shared/Types/appTypes"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { selectFlow } from "shared/Modules/Login/loginActions"
import { LoginChoice } from "shared/Modules/Login/loginTypes"
import { FlowDetails } from "shared/Modules/Transaction/transactionTypes"

function orderTypeToRoute(shopType: WebshopType) {
    switch (shopType) {
        case WebshopType.LUNCH:
            return "lunch"
        case WebshopType.TAKE_AWAY:
            return "takeaway"
        case WebshopType.CATERING:
            return "catering"
        default:
            // In the future we will no longer use order type as part of route
            // so we will never need to add more cases here
            throw new Error("Unsupported shop type for route: " + shopType)
    }
}

function orderTypeToLegacyRoute(shopType: WebshopType) {
    switch (shopType) {
        case WebshopType.LUNCH:
            return "catering"
        case WebshopType.TAKE_AWAY:
            return "takeaway"
        default:
            // In the future we will no longer use order type as part of route
            // so we will never need to add more cases here
            throw new Error("Unsupported shop type for legacy route: " + shopType)
    }
}

export function useAppNavigation() {
    const history = useHistory()
    const match = useRouteMatch("/guest")
    const { l10n } = useLocalization()
    const token = useSelector(selectApiToken)
    const dispatch = useDispatch()

    const guest = match !== null
    const loggedIn = token !== null

    const goToHomeLabel = useMemo(() => {
        if (guest) return l10n.getString("app-navigation-to-webshop-select", undefined, "Gå til valg af webshop")
        if (!loggedIn) return l10n.getString("app-navigation-to-login", undefined, "Gå til log ind")
        return l10n.getString("app-navigation-to-home", undefined, "Gå til forside")
    }, [l10n, guest, loggedIn])

    const goToHome = useCallback(() => {
        if (guest) {
            history.push("/guest/shops/kitchen")
        } else if (!loggedIn) {
            // Reset flow when navigating back past choice
            dispatch(selectFlow(undefined))
            history.push("/login/choices")
        } else {
            history.push("/")
        }
    }, [history, guest])

    const goToHomeNoGuest = useCallback(() => {
        if (!loggedIn) {
            // Reset flow when navigating back past choice
            dispatch(selectFlow(undefined))
            history.push("/login/choices")
        } else {
            history.push("/")
        }
    }, [history])

    const goToLogin = useCallback(() => {
        // Reset flow when navigating back past choice
        dispatch(selectFlow(undefined))
        history.push("/login")
    }, [history])

    const goToAutoLogin = useCallback((oneTimeToken: string) => {
        dispatch(selectFlow({ loginChoice: LoginChoice.Login }))
        history.push(`/login/otp?token=${oneTimeToken}`)
    }, [history])

    const goToWebshop = useCallback((kitchenId: number, orderType: WebshopType, date?: DateTime) => {
        const dateRoute = date ? `/${date.toISODate()}` : ""
        if (guest) history.push(`/guest/shops/${orderTypeToRoute(orderType)}/${kitchenId}${dateRoute}`)
        else history.push(`/order/${kitchenId}/${orderTypeToLegacyRoute(orderType)}${dateRoute}`)
    }, [history, guest])

    const goToCheckout = useCallback((kitchenId: number, orderType: WebshopType) => {
        if (guest) history.push(`/guest/shops/${orderTypeToRoute(orderType)}/${kitchenId}/basket`)
        else history.push(`/order/${kitchenId}/${orderTypeToLegacyRoute(orderType)}/basket`)
    }, [history, guest])

    const goToWallet = useCallback(() => {
        history.push("/refill-account")
    }, [history])

    const goToAutomaticRefillWallet = useCallback(() => {
        history.push("/automatic-refill-account")
    }, [history])

    const goToSubscribeToLunch = useCallback(() => {
        history.push("/lunch-subscriptions/subscribe")
    }, [history])

    const goToPrepareTransaction = useCallback(() => {
        history.push("/transaction/prepare")
    }, [history])

    const goToPaymentPoller = useCallback((orderUid: string, flowDetails: FlowDetails) => {
        const guestPath = guest ? "/guest" : ""
        history.push(`${guestPath}/quickpay/finalized/${orderUid}?type=${flowDetails.type}&source=${flowDetails.source}`)
    }, [history, guest])

    const goToPaymentCanceled = useCallback((orderUid: string) => {
        const guestPath = guest ? "/guest" : ""
        history.push(`${guestPath}/quickpay/canceled/${orderUid}`)
    }, [history, guest])

    const goToSingleSignOnCanceled = useCallback(() => {
        history.push("/login/single-sign-on?canceled=true")
    }, [history])

    const goToPrevious = useCallback(() => {
        if (history.length === 0) goToHome()
        else history.goBack()
    }, [history])

    const goToUserAccount = useCallback(() => {
        history.push("/user-account")
    }, [history])

    return {
        isGuest: guest,
        goToHomeLabel,
        goToHome,
        goToHomeNoGuest,
        goToLogin,
        goToAutoLogin,
        goToWebshop,
        goToCheckout,
        goToWallet,
        goToAutomaticRefillWallet,
        goToSubscribeToLunch,
        goToPrepareTransaction,
        goToPaymentPoller,
        goToPaymentCanceled,
        goToSingleSignOnCanceled,
        goToPrevious,
        goToUserAccount
    }
}
